import styled from 'styled-components'
import React from 'react'

export const Intro = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 185px;

	@media (max-width: 1024px) {
		margin-bottom: 128px;
	}

	@media (max-width: 800px) {
		flex-wrap: wrap;
	}

	@media (max-width: 525px) {
		/* margin-bottom: 90px; */
		margin-bottom: 28px;
	}
`

export const Header = styled.h1`
	font-size: 2.625rem !important;
	line-height: 3.75rem !important;
	max-width: 605px;
	width: 45%;

	@media (max-width: 1024px) {
		font-size: 2rem !important;
		line-height: 2.75rem !important;
	}

	@media (max-width: 800px) {
		width: 100%;
		margin-bottom: 64px;
		font-size: 2rem !important;
	}

	@media (max-width: 525px) {
		font-size: 1.875rem !important;
		line-height: 2.75rem !important;
		max-width: 300px;
	}
`

// export const IntroVideo = styled.div`
// 	margin-bottom: 0;
// 	padding-top: 0;
// 	padding-bottom: 29.25%;
// 	width: 51.6% !important;
// 	background-color: #dadada;

// 	@media (max-width: 800px) {
// 		width: 100% !important;
// 		padding-bottom: 56.25%;
// 	}
// `

export const OurWork = styled.div`
	margin-bottom: 200px;
	display: flex;
	align-items: center;
	mix-blend-mode: difference;

	p {
		font-size: 0.875rem;
		line-height: 1.3125rem;
		letter-spacing: 0.34px;
		text-transform: uppercase;
		margin: 0;
		margin-right: 1rem;
		font-family: 'Poppins Regular';
		color: white;
	}

	svg path {
		stroke: ${({ theme }) => theme.blendLink};
	}

	@media (max-width: 1024px) {
		margin-bottom: 128px;
	}

	@media (max-width: 800px) {
		display: none;
	}
`
export const WorkText = styled.div`
	margin: 255px 0;

	& > div {
		max-width: 1000px;
		margin: 0 auto;
		text-align: center;
	}

	p {
		margin-bottom: 50px;
		font-size: 1.5rem;
		line-height: 2.5rem;
	}

	@media (max-width: 1024px) {
		margin: 12.8em 0;

		p {
			font-size: 0.875rem;
			line-height: 1.625rem;
		}
	}

	@media (max-width: 525px) {
		margin: 9em 0;
	}
`

export const ServiceIntro = styled.div`
	text-align: center;
`
export const STitleText = styled.div`
	margin-bottom: 19em;

	& > div {
		max-width: 100%;
	}

	h2 {
		font-size: 2.375rem;
		line-height: 3.25rem;
		margin-bottom: 2rem;
	}

	p {
		max-width: 1000px;
		margin: 0 auto;
		font-size: 1.5rem;
		line-height: 2.5rem;
	}

	@media (max-width: 1024px) {
		margin-bottom: 9em;

		p {
			font-size: 0.875rem;
			line-height: 1.625rem;
		}

		h2 {
			font-size: 1.4375rem;
			line-height: 2rem;
			margin-bottom: 0;
		}
	}

	@media (max-width: 525px) {
		margin-bottom: 6em;
	}
`
export const ServiceWrap = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 1000px;
	margin: 0 auto;

	@media (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;
	}
`
export const Item = styled.div`
	max-width: 432px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	img {
		margin-bottom: 65px;
		z-index: 1;
	}

	&:nth-of-type(2) img {
		margin-top: 31px;
	}

	.title {
		flex: 1;
	}

	p {
		font-size: 1.25rem;
		line-height: 2.125rem;
	}

	h4 {
		font-size: 1.75rem;
		line-height: 2.625rem;
		margin-bottom: 2rem;
	}

	@media (max-width: 1024px) {
		h4 {
			font-size: 1.25rem;
			line-height: 2rem;
			margin-bottom: 1rem;
		}

		p {
			font-size: 0.875rem;
			line-height: 1.625rem;
		}
	}

	@media (max-width: 900px) {
		align-items: center;

		p {
			max-width: 340px;
			margin: 0 auto;
		}
	}

	@media (max-width: 768px) {
		max-width: 100%;

		&:nth-of-type(1) {
			margin-bottom: 6em;
		}

		img {
			width: 40%;
			margin: 0 auto;
			margin-bottom: 2.4em;
		}

		h4 {
			max-width: 310px;
			margin-left: auto;
			margin-right: auto;
		}

		p {
			font-size: 1rem;
			line-height: 1.5625rem;
		}
	}

	@media (max-width: 525px) {
		&:nth-of-type(1) {
			margin-bottom: 3em;
		}

		p {
			font-size: 0.875rem;
			line-height: 1.625rem;
		}
	}
`

/******************************************
 * Gatsby bug (file must export component)
 *****************************************/
const Style = () => {
	return <div>style</div>
}

export default Style
