import React, { useContext } from 'react'
// import slugify from 'slugify'
import HoverContext from '../context/HoverContext'

// Gatsby
import Img from 'gatsby-image'

// Styles
import {
	Flex,
	Column,
	Info,
	ClientWrap,
	ArchiveClient,
	Description,
	PCta,
	ImageWrap,
} from './style'

// Components
import Paragraph from '../components/ui/Paragraph'
import Link from '../components/ui/Link'

const ProjectList = React.memo(({ projects, archive }) => {
	const { setCursorStyle } = useContext(HoverContext)

	return (
		<React.Fragment>
			<Flex>
				{projects
					.slice(0)
					.reverse()
					.map((project, index) => {
						const {
							Client,
							Type,
							ArchiveDescription,
							GradientDark,
							GradientLight,
							Logo,
							ArchiveImage,
							ArchiveImageMobile,
						} = project

						return (
							<Column key={project.id}>
								<Info>
									<ClientWrap>
										<img
											loading="lazy"
											src={Logo.imageFile.publicURL}
											alt={Logo.alternativeText}
											title={Logo.caption}
										/>
										<ArchiveClient key={project.id}>
											<Link
												to={'/project/' + project.Seo.Url}
												onMouseEnter={() => {
													setCursorStyle({
														height: 0,
														width: 0,
														transition: '.1.2s',
													})
												}}
												onMouseLeave={() => {
													setCursorStyle({
														height: false,
														width: false,
														transition: false,
													})
												}}
											>
												{' '}
												{Client}
											</Link>
										</ArchiveClient>
									</ClientWrap>
									<Description as={Paragraph}>{ArchiveDescription}</Description>
									<PCta>
										<Link
											to={'/project/' + project.Seo.Url}
											onMouseEnter={() => {
												setCursorStyle({
													height: 0,
													width: 0,
													transition: '.1.2s',
												})
											}}
											onMouseLeave={() => {
												setCursorStyle({
													height: false,
													width: false,
													transition: false,
												})
											}}
										>
											View project
										</Link>
									</PCta>
								</Info>
								{ArchiveImage !== null && ArchiveImageMobile !== null && (
									<React.Fragment>
										{index > 1 ? (
											<ImageWrap
												to={'/project/' + project.Seo.Url}
												gradientdark={GradientDark}
												gradientlight={GradientLight}
												className={Type}
												onMouseEnter={() => {
													setCursorStyle({
														height: 0,
														width: 0,
														transition: '.1.2s',
													})
												}}
												onMouseLeave={() => {
													setCursorStyle({
														height: false,
														width: false,
														transition: false,
													})
												}}
											>
												<Img
													className={'nm'}
													fluid={ArchiveImage.imageFile.childImageSharp.fluid}
													alt={ArchiveImage.alternativeText}
													title={ArchiveImage.caption}
												/>
												<Img
													className="mm"
													fluid={
														ArchiveImageMobile.imageFile.childImageSharp.fluid
													}
													alt={ArchiveImageMobile.alternativeText}
													title={ArchiveImageMobile.caption}
												/>
											</ImageWrap>
										) : (
											<ImageWrap
												to={'/project/' + project.Seo.Url}
												gradientdark={GradientDark}
												gradientlight={GradientLight}
												className={Type}
												onMouseEnter={() => {
													setCursorStyle({
														height: 0,
														width: 0,
														transition: '.1.2s',
													})
												}}
												onMouseLeave={() => {
													setCursorStyle({
														height: false,
														width: false,
														transition: false,
													})
												}}
											>
												<img
													className="nm"
													loading="lazy"
													src={ArchiveImage.imageFile.publicURL}
													alt={ArchiveImage.alternativeText}
													title={ArchiveImage.caption}
												/>
												<img
													className="mm"
													loading="lazy"
													src={ArchiveImageMobile.imageFile.publicURL}
													alt={ArchiveImageMobile.alternativeText}
													title={ArchiveImageMobile.caption}
												/>
											</ImageWrap>
										)}
									</React.Fragment>
								)}
							</Column>
						)
					})}
			</Flex>
		</React.Fragment>
	)
})

export default ProjectList
