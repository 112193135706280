import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Styles
import {
	Intro,
	Header,
	OurWork,
	WorkText,
	ServiceIntro,
	STitleText,
	ServiceWrap,
	Item,
} from './style'

// Components
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Container from '../../components/ui/Container'
import ProjectList from '../../templates/projectList'
import Heading from '../../components/ui/Heading'
// import Video from '../components/ui/Video'
import Paragraph from '../../components/ui/Paragraph'
import CTA from '../../components/ui/CTA'
import TitleText from '../../components/ui/TitleText'

const IndexPage = React.memo((props) => {
	const data = useStaticQuery(graphql`
		query Home {
			timacum {
				page(id: "5f44d6a3e2f2501e1e0d39ff") {
					Title
					Seo {
						MetaDescription
						TitleTag
					}
					Fields {
						... on TIMACUM_ComponentContentCta {
							id
							CTA
							Link
						}
						... on TIMACUM_ComponentContentText {
							id
							Text
						}
						... on TIMACUM_ComponentContentTitle {
							id
							Title
						}
						... on TIMACUM_ComponentContentTitleText {
							id
							Text
							Title
						}
						... on TIMACUM_ComponentContentTitleTextImage {
							id
							Title
							Text
							Image {
								url
								alternativeText
								caption
								imageFile {
									id
									publicURL
								}
							}
						}
					}
					projects {
						id
						Client
						Seo {
							Url
						}
						Type
						ArchiveDescription
						GradientDark
						GradientLight
						ArchiveImage {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
						ArchiveImageMobile {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
						Logo {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 600, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	const { Fields, projects, Seo } = data.timacum.page

	return (
		<Layout location={props.location}>
			<SEO data={Seo} />
			<Container>
				<Intro>
					<Header as={Heading} level={1}>
						{Fields[0].Title}
					</Header>
					{/*<IntroVideo as={Video} url={Fields[1].Link} /> */}
				</Intro>
				<OurWork>
					<p>Our Work</p>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="10.707"
						height="32.707"
						viewBox="0 0 10.707 32.707"
					>
						<g id="our-work-arrow" transform="translate(-963.647 -880)">
							<path
								id="Path_40"
								data-name="Path 40"
								d="M966.635,866.21l5,5-5,5"
								transform="translate(1840.21 -59.635) rotate(90)"
								fill="none"
								stroke="#ff7800"
								strokeWidth="1"
							/>
							<path
								id="Path_41"
								data-name="Path 41"
								d="M986.583,912.9v-32"
								transform="translate(-17.583 -0.898)"
								fill="none"
								stroke="#ff7800"
								strokeWidth="1"
							/>
						</g>
					</svg>
				</OurWork>
				<ProjectList projects={projects} />
				<WorkText>
					<Paragraph>{Fields[3].Text}</Paragraph>
					<CTA link={Fields[4].Link}>{Fields[4].CTA}</CTA>
				</WorkText>
				<ServiceIntro>
					<STitleText
						as={TitleText}
						heading={Fields[5].Title}
						text={Fields[5].Text}
						level={2}
					/>
					<ServiceWrap>
						<Item>
							<img
								loading="lazy"
								src={Fields[6].Image.imageFile.publicURL}
								alt={Fields[6].Image.alternativeText}
								title={Fields[6].Image.caption}
							/>
							<Heading level={4}>{Fields[6].Title}</Heading>
							<Paragraph>{Fields[6].Text}</Paragraph>
						</Item>
						<Item>
							<img
								loading="lazy"
								src={Fields[7].Image.imageFile.publicURL}
								alt={Fields[7].Image.alternativeText}
								title={Fields[7].Image.caption}
							/>
							<Heading level={4}>{Fields[7].Title}</Heading>
							<Paragraph>{Fields[7].Text}</Paragraph>
						</Item>
					</ServiceWrap>
				</ServiceIntro>
			</Container>
		</Layout>
	)
})

export default IndexPage
